import React, { ReactElement } from "react";
import colors from "@styles/variables/taxdebtoffersVariables.module.scss";

import IdentifyIssueIcon from "../icons/identifyIssueIcon";
import InvestigateCauseIcon from "../icons/investigateCauseIcon";
import FindSolutionIcon from "../icons/findSolutionIcon";
import BuildCaseIcon from "../icons/buildCaseIcon";
import SubmitTheCaseIcon from "../icons/submitTheCaseIcon";
import ReceiveTaxReliefIcon from "../icons/receiveTaxReliefIcon";
import FeatureList from "@components/shared/featureList";

const whyUsList = [
    {
        description:
            "Clarify the Problem: Determine the exact amount you owe and check if the IRS has placed any holds or liens on your assets.",
        icon: <IdentifyIssueIcon />,
    },
    {
        description:
            "Identify the Root Cause: Assess whether errors in your return, insufficient income, or lack of collateral assets contributed to the issue.",
        icon: <InvestigateCauseIcon />,
    },
    {
        description:
            "Determine a Solution: Select the debt relief program most suitable for your specific circumstances.",
        icon: <FindSolutionIcon />,
    },
    {
        description:
            "Develop Your Case: Prepare a detailed explanation of why you're unable to pay the tax debt, along with your proposed resolution plan.",
        icon: <BuildCaseIcon />,
    },
    {
        description:
            "Submit Your Case: Present your prepared case to the IRS for review.",
        icon: <SubmitTheCaseIcon />,
    },
    {
        description:
            "Obtain Tax Relief: Upon approval, receive the relief specific to the program you applied for.",
        icon: <ReceiveTaxReliefIcon />,
    },
];

export default function WhyUs(): ReactElement {
    return (
        <FeatureList
            featureList={whyUsList}
            mainTitle="Steps for Finding Tax Debt Relief"
            mainDescription="If you owe taxes to the IRS, obtaining tax debt relief involves several key steps:"
            classes={{
                mainSectionClasses: "max-w-7xl mx-auto block px-4vw lg:px-4 ",
                mainTitleClasses:
                    "text-center font-bold text-3xl lg:text-4xl mb-4",
                mainDescriptionClasses:
                    "text-base lg:text-xl text-center  p-0 m-0 mb-16",
                innerSectionClasses: "lg:flex flex-wrap justify-center",
                oneBoxClasses: "text-center mb-12 lg:mb-20 w-full lg:w-1/3",
                iconClasses: "children:mx-auto",
                descriptionClasses:
                    "font-light text-sm text-center w-4/5 mx-auto mt-2",
            }}
            colors={{
                mainDescriptionColor: "#4b4b4b",
                descriptionColor: colors.whyUsBriefColor,
            }}
        />
    );
}

import React, { ReactElement } from "react";

export default function FindSolutionIcon(): ReactElement {
    return (
        <svg
            width="107"
            height="85"
            viewBox="0 0 107 85"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="67.5" cy="54.5" r="25.5" fill="#FFE5DC" />
            <path
                d="M16.0396 63.2527L31.5852 53.1825H50.3866C51.0469 53.1825 51.6802 53.4427 52.1471 53.9058C52.6141 54.369 52.8764 54.9972 52.8764 55.6522V58.2796H38.6454C38.2956 58.2796 37.96 58.4174 37.7126 58.6628C37.4652 58.9082 37.3263 59.241 37.3263 59.5881C37.3262 59.7599 37.3603 59.9301 37.4266 60.089C37.4928 60.2478 37.59 60.3921 37.7125 60.5136C37.835 60.6352 37.9804 60.7316 38.1405 60.7974C38.3006 60.8632 38.4722 60.897 38.6454 60.897H54.0136C54.0498 60.897 54.0851 60.8943 54.1209 60.8916C54.4619 60.912 54.7976 60.8001 55.0571 60.5795L71.2491 46.7333C71.7143 46.3337 72.3126 46.1198 72.9278 46.1331C73.5431 46.1464 74.1314 46.3859 74.5787 46.8052C74.8302 47.0381 75.0297 47.3208 75.1642 47.635C75.2987 47.9492 75.3653 48.2879 75.3596 48.6292C75.3577 48.9705 75.2835 49.3077 75.142 49.6189C75.0005 49.9301 74.7947 50.2083 74.5379 50.4357L56.0702 66.9447C55.3696 67.5719 54.4593 67.9188 53.5156 67.9183H34.4313C34.2155 67.9183 34.0031 67.9707 33.8124 68.0709L23.4457 73.534C23.1368 73.6969 22.9057 73.9749 22.8033 74.3067C22.701 74.6386 22.7356 74.9972 22.8997 75.3037C23.0121 75.5137 23.18 75.6894 23.3855 75.8119C23.5909 75.9343 23.8262 75.9989 24.0659 75.9988C24.2814 75.9987 24.4936 75.9461 24.6838 75.8457L34.7604 70.5353H53.5165C55.1129 70.5363 56.6528 69.9495 57.838 68.8886L76.3057 52.3778C76.8315 51.9066 77.2533 51.3326 77.5448 50.692C77.8363 50.0514 77.9912 49.358 77.9997 48.6552C78.0065 47.9524 77.8667 47.2559 77.5891 46.6092C77.3115 45.9626 76.9022 45.3799 76.3868 44.8976C75.4651 44.0339 74.2529 43.5404 72.9853 43.5129C71.7177 43.4855 70.4849 43.926 69.5261 44.749L55.5147 56.7304V55.6504C55.513 54.3022 54.9723 53.0097 54.0112 52.0565C53.0501 51.1032 51.7471 50.567 50.3879 50.5654H31.1936C30.9373 50.5653 30.6865 50.6392 30.472 50.7783L14.5977 61.0614C14.3048 61.2512 14.0999 61.5487 14.0281 61.8884C13.9563 62.2281 14.0234 62.5822 14.2147 62.8728C14.4061 63.1633 14.7059 63.3665 15.0483 63.4378C15.3907 63.509 15.7476 63.4425 16.0405 63.2527H16.0396Z"
                fill="#EB7F58"
            />
            <path
                d="M48.0746 7.61658C54.0439 7.61658 58.9004 12.4339 58.9004 18.355C58.9036 20.4635 58.278 22.5258 57.1023 24.2824C57.0838 24.3102 57.0661 24.3389 57.0498 24.3681C56.5066 25.2263 53.4042 30.2645 52.7315 34.0033H43.4177C42.745 30.2627 39.6382 25.2213 39.099 24.3668C39.0827 24.338 39.0655 24.3098 39.0469 24.2824C37.8712 22.5258 37.2456 20.4635 37.2488 18.355C37.2488 12.4339 42.1054 7.61658 48.0746 7.61658ZM36.8305 25.7018C37.9645 27.4867 40.9089 32.692 40.9089 35.3113C40.9088 35.4832 40.9429 35.6534 41.0092 35.8122C41.0754 35.971 41.1726 36.1153 41.2951 36.2369C41.4176 36.3584 41.563 36.4548 41.7231 36.5206C41.8832 36.5864 42.0548 36.6203 42.228 36.6203H53.9235C54.2734 36.6202 54.6089 36.4822 54.8563 36.2367C55.1037 35.9913 55.2426 35.6584 55.2426 35.3113C55.2426 32.6979 58.1852 27.4894 59.321 25.7018C59.3282 25.6906 59.3355 25.6789 59.3423 25.6672C60.7793 23.4953 61.5434 20.9534 61.5401 18.3554C61.5401 10.9911 55.4998 5 48.0755 5C40.6513 5 34.6123 10.9907 34.6123 18.355C34.6089 20.9534 35.3732 23.4959 36.8106 25.6681C36.8174 25.6793 36.8242 25.6906 36.8314 25.7018H36.8305Z"
                fill="#EB7F58"
            />
            <path
                d="M55.1895 39.808C55.1895 39.4609 55.0505 39.128 54.8031 38.8826C54.5558 38.6371 54.2202 38.4991 53.8703 38.499H42.4895C42.1395 38.499 41.8039 38.6369 41.5564 38.8824C41.309 39.1279 41.1699 39.4608 41.1699 39.808C41.17 40.1551 41.3091 40.4879 41.5566 40.7333C41.804 40.9787 42.1396 41.1165 42.4895 41.1165H53.8685C54.0418 41.1165 54.2133 41.0826 54.3733 41.0169C54.5334 40.9511 54.6788 40.8548 54.8013 40.7333C54.9238 40.6117 55.021 40.4675 55.0873 40.3087C55.1535 40.15 55.1877 39.9798 55.1877 39.808H55.1895Z"
                fill="#EB7F58"
            />
            <path
                d="M45.1297 42.7803C44.7798 42.7804 44.4443 42.9183 44.1969 43.1638C43.9495 43.4093 43.8105 43.7422 43.8105 44.0893C43.8105 44.2611 43.8447 44.4313 43.911 44.5901C43.9773 44.7488 44.0744 44.8931 44.1969 45.0146C44.3194 45.1361 44.4648 45.2325 44.6249 45.2983C44.7849 45.364 44.9565 45.3979 45.1297 45.3979H51.2316C51.4048 45.3979 51.5764 45.364 51.7364 45.2983C51.8965 45.2325 52.0419 45.1361 52.1644 45.0146C52.2869 44.8931 52.3841 44.7488 52.4503 44.5901C52.5166 44.4313 52.5508 44.2611 52.5508 44.0893C52.5508 43.9174 52.5167 43.7472 52.4505 43.5884C52.3842 43.4296 52.2871 43.2853 52.1646 43.1637C52.0421 43.0421 51.8966 42.9457 51.7365 42.8799C51.5765 42.8141 51.4049 42.7803 51.2316 42.7803H45.1297Z"
                fill="#EB7F58"
            />
            <path
                d="M45.6008 25.2059C45.7232 25.3276 45.8686 25.4241 46.0287 25.49C46.1889 25.5558 46.3605 25.5895 46.5338 25.5893C46.7071 25.5895 46.8786 25.5557 47.0387 25.4899C47.1987 25.4241 47.344 25.3276 47.4664 25.2059L55.5171 17.2205C55.7646 16.9751 55.9035 16.6422 55.9035 16.2951C55.9035 15.9479 55.7646 15.6151 55.5171 15.3696C55.2697 15.1242 54.9342 14.9863 54.5844 14.9863C54.2345 14.9863 53.899 15.1242 53.6516 15.3696L46.5338 22.4299L43.5913 19.5111C43.3438 19.2657 43.0082 19.1278 42.6583 19.1278C42.3084 19.1278 41.9727 19.2657 41.7253 19.5111C41.4779 19.7566 41.3389 20.0895 41.3389 20.4366C41.3389 20.7837 41.4779 21.1166 41.7253 21.3621L45.5995 25.2045L45.6008 25.2059Z"
                fill="#EB7F58"
            />
        </svg>
    );
}

import React, { ReactElement } from "react";

export default function BuildCaseIcon(): ReactElement {
    return (
        <svg
            width="107"
            height="85"
            viewBox="0 0 107 85"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="25.5" cy="57.5" r="25.5" fill="#FFE5DC" />
            <path
                d="M40.7454 62.0275V71.5003H13.4872V62.0275H40.7454ZM42.2326 60.5278H12V73H42.2326V60.5278Z"
                fill="#EB7F58"
            />
            <path
                d="M73.1294 62.0275V71.4997H45.8712V62.0275H73.1294ZM74.6166 60.5278H44.384V72.9994H74.6166V60.5278Z"
                fill="#EB7F58"
            />
            <path
                d="M105.513 62.0275V71.4997H78.2545V62.0275H105.513ZM107 60.5278H76.7673V72.9994H107V60.5278Z"
                fill="#EB7F58"
            />
            <path
                d="M24.5541 47.5V56.9722H13.4872V47.5H24.5541ZM26.0413 46.0003H12V58.4719H26.0413V46.0003Z"
                fill="#EB7F58"
            />
            <path
                d="M56.9374 47.5007V56.9722H29.6792V47.5007H56.9374ZM58.4246 46.001H28.192V58.4719H58.4246V46.001Z"
                fill="#EB7F58"
            />
            <path
                d="M89.3207 47.5007V56.9722H62.0625V47.5007H89.3207ZM90.8079 46.001H60.5752V58.4719H90.8079V46.001Z"
                fill="#EB7F58"
            />
            <path
                d="M40.7454 32.8983V42.3705H13.4872V32.8983H40.7454ZM42.2326 31.3986H12V43.8701H42.2326V31.3986Z"
                fill="#EB7F58"
            />
            <path
                d="M73.1294 32.8983V42.3705H45.8712V32.8983H73.1294ZM74.6166 31.3986H44.384V43.8701H74.6166V31.3986Z"
                fill="#EB7F58"
            />
            <path
                d="M25.6294 18.1107V27.5829H13.4872V18.1107H25.6294ZM27.1166 16.611H12V29.0825H27.1166V16.611Z"
                fill="#EB7F58"
            />
            <path
                d="M58.0127 18.1113V27.5835H30.7545V18.1113H58.0127ZM59.5 16.6116H29.2673V29.0832H59.5V16.6116Z"
                fill="#EB7F58"
            />
            <path
                d="M40.7455 3.49969V12.9712H13.4873V3.49969H40.7455ZM42.2327 2H12V14.4709H42.2327V2Z"
                fill="#EB7F58"
            />
        </svg>
    );
}
